import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import useClassrooms from "./_hooks/useClassrooms";
import ClassroomAssignmentsContainer from "./components/ClassroomAssignmentsContainer";
import useClassroomsPageStyles from "./styles";
import Banner from "./components/Banner";

const ClassroomsPage = ({ isLearnPage, classroomId }) => {
	const { state, setClassrooms, onToggle } = useClassrooms();
	const styles = useClassroomsPageStyles();

	useEffect(() => {
		setClassrooms(classroomId);
	}, []);

	return (
		<>
			<Banner isLearnPage={isLearnPage} />
			<div className={styles.classrooms}>
				<Grid container spacing={0}>
					{state.classrooms.map(classroom => (
						<ClassroomAssignmentsContainer
							key={classroom.id}
							isLearnPage={isLearnPage}
							classroom={classroom}
							assignments={state.assignmentData[classroom.id] || []}
							isExpanded={state.expanded[classroom.id]}
							onToggle={async () => await onToggle(classroom.id)}
						/>
					))}
				</Grid>
			</div>
		</>
	);
};

export default ClassroomsPage;
