/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from "react";
import {
	Switch,
	Route,
	Redirect,
	useRouteMatch,
	useLocation,
	useHistory,
	useParams
} from "react-router-dom";
import * as Sentry from "@sentry/browser";
import LoginPage from "pages/LoginPage";
import AssignmentPage from "pages/AssignmentPage";
import ClassroomsPage from "pages/ClassroomsPage";
import ErrorPage from "pages/ErrorPage";
import { Amplify } from "aws-amplify";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { fetchUserAttributes, getCurrentUser, signOut } from "aws-amplify/auth";
import TopNav from "components/TopNav";
import { postUserActivity } from "lib/api/primerApi";
import { useUnleashContext } from "@unleash/proxy-client-react";

const setupAuth = () => {
	// globally configure AWS cognito
	const authConfig = {
		Cognito: {
			userPoolId: process.env.REACT_APP_USER_POOL_ID,
			userPoolClientId: process.env.REACT_APP_WEB_CLIENT_ID
		}
	};
	Amplify.configure({
		Auth: authConfig
	});
	cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
};

const emptyState = {
	loggedIn: false,
	loadingUser: true,
	loadingOrganization: true,
	initials: "",
	fullName: "",
	districtName: "",
	appKey: "",
	districtId: -1,
	error: false
};

export const getUsername = fullUsername => {
	return fullUsername.split("__")[1];
};

export const getInitials = (firstName, lastName) => {
	return firstName.charAt(0) + lastName.charAt(0);
};
export const getUserIdFromAttributes = attrs =>
	Number(attrs["custom:student_id"]) || -1;

const StudentRouter = () => {
	const { path, url } = useRouteMatch();
	const { districtKey } = useParams();
	const location = useLocation();
	const history = useHistory();
	const updateFeatureFlagContext = useUnleashContext();

	const [state, setState] = useState(emptyState);

	const onSRPLogin = async () => {
		const { username } = await getCurrentUser();
		const attributes = await fetchUserAttributes();
		const userId = getUserIdFromAttributes(attributes);

		postUserActivity({
			eventKey: "student_login",
			userId
		});
		setState({
			...state,
			loggedIn: true,
			username: getUsername(username),
			initials: getInitials(attributes.name, attributes.family_name),
			fullName: attributes.name + " " + attributes.family_name
		});
		Sentry.setUser({ id: userId });
		if (location.state?.from) {
			history.push(location.state.from);
		}
	};

	// Note: cognito is configured so localstorage is cached after logout. For now, we have
	// decided to leave this be, as it does not pose any major security risk and the fix
	// seems to be involved.
	const onLogout = () => {
		setState(emptyState);
		signOut().catch(err => console.log(err));
		const districtHash = url.split("/")[1];
		window.location.assign("/" + districtHash + "/login");
	};

	// eslint-disable-next-line
	const PrivateRoute = ({ render, children, ...params }) => {
		const loginRedirect = ({ location }) => (
			<Redirect
				to={{
					pathname: `${url}/login`,
					state: { from: location }
				}}
			/>
		);

		const normal = () => children;

		return (
			<Route {...params} render={state.loggedIn ? normal : loginRedirect} />
		);
	};

	useEffect(() => setupAuth(), []);

	useEffect(() => {
		let subscribed = true;
		const fetchUser = async () => {
			try {
				const { username } = await getCurrentUser();
				const attributes = await fetchUserAttributes();
				const userId = getUserIdFromAttributes(attributes);
				Sentry.setUser({ id: userId });
				subscribed &&
					setState(prev => ({
						...prev,
						loadingUser: false,
						loggedIn: true,
						username: getUsername(username),
						initials: getInitials(attributes.name, attributes.family_name),
						fullName: attributes.name + " " + attributes.family_name
					}));
			} catch (err) {
				subscribed &&
					setState(prev => ({
						...prev,
						loadingUser: false,
						error: err?.name !== "UserUnAuthenticatedException" || prev.error
					}));
			}
		};
		const fetchOrganization = async () => {
			try {
				const orgResults = await fetch(
					`${process.env.REACT_APP_API_ENDPOINT}-sp1/organization?key=${districtKey}`
				);
				if (orgResults.status >= 300) {
					history.replace("/");
					return;
				}

				const { result } = await orgResults.json();

				updateFeatureFlagContext({ districtId: result.districtId });
				subscribed &&
					setState(prev => {
						return {
							...prev,
							loadingOrganization: false,
							appKey: result.key,
							districtName: result.title,
							districtId: result.districtId
						};
					});
			} catch (err) {
				subscribed && setState(prev => ({ ...prev, error: true }));
			}
		};

		fetchUser();
		fetchOrganization();

		return () => {
			subscribed = false;
		};
	}, [districtKey]);

	if (state.error) {
		return <ErrorPage />;
	}

	if (state.loadingUser || state.loadingOrganization) {
		// Should we have a brief loading state?
		return <div />;
	}
	return (
		<Switch>
			{/* This route cannot be private! Don't change this */}
			<Route
				path={`${path}/assignment/:id`}
				render={
					state.loggedIn
						? () => (
								<AssignmentPage
									exitDestination={
										location.state && location.state.exitDestination
									}
								/>
							)
						: () => (
								<Redirect
									to={{
										pathname: `${url}/login`,
										state: { from: location }
									}}
								/>
							)
				}
			/>
			<Route path={"*"}>
				<TopNav
					loggedIn={state.loggedIn}
					districtName={state.districtName}
					initials={state.initials}
					fullName={state.fullName}
					username={state.username}
					onLogout={onLogout}
				/>
				<Switch>
					<Route
						path={`${path}/login`}
						render={
							state.loggedIn
								? () => <Redirect to={`${url}/learn`} />
								: () => (
										<LoginPage
											districtId={state.districtId}
											onSRPLogin={onSRPLogin}
										/>
									)
						}></Route>
					<PrivateRoute path={`${path}/learn`}>
						<ClassroomsPage
							isLearnPage
							classroomId={location.state && location.state.classroomId}
						/>
					</PrivateRoute>
					<PrivateRoute path={`${path}/review`}>
						<ClassroomsPage
							classroomId={location.state && location.state.classroomId}
						/>
					</PrivateRoute>
					<Route path={`${path}/error`}>
						<ErrorPage />
					</Route>
					<Route path={path}>
						<Redirect to={`${url}/learn`} />
					</Route>
				</Switch>
			</Route>
		</Switch>
	);
};

export default StudentRouter;
