import React from "react";
import usePopoverContentStyles from "./styles";

const PopoverContent = ({ initials, fullName, username, onLogout }) => {
	const classes = usePopoverContentStyles();
	return (
		<ul className={classes.ersMenu}>
			<li className={classes.ersUserMenuDetailed}>
				<div className={classes.userInfo}>
					<span
						className={`${classes.ersUserAvatar} ${classes.ersPopoverAvatar}`}
					>
						{initials}
					</span>
					<span>
						<span className={classes.ersUserName}>{fullName}</span>
						<span className={classes.ersUserLinks}>{username}</span>
					</span>
				</div>
			</li>
			<li className={classes.ersDivider}></li>
			<li className={classes.signOut}>
				<a className={classes.ersSecondaryButton} onClick={onLogout}>
					Sign Out
				</a>
			</li>
			<li className={classes.ersUserActions}>
				<a
					className={classes.privacyLink}
					href={"https://ellevationeducation.com/privacy-policy"}
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</a>
				<a
					className={classes.privacyLink}
					href={"https://ellevationeducation.com/platform-legal-notices"}
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms of Service
				</a>
			</li>
		</ul>
	);
};
export default PopoverContent;
